import { PageInfoLinkProps, PageInfoProps, SEOProps } from './pages'
import { PublicationProps, PublicationFormattedProps } from './pages'
import { GatsbyImageBasicProps } from './images'
import { Record, StructuredText } from 'datocms-structured-text-utils'
import { ButtonProps } from './buttons'
import { HubspotFormProps, SocialProps, TypeformFormProps } from './blocks'

/*
 * Auhtor
 */
export interface AuthorProps {
  id: string
  pageInfo: PageInfoLinkProps
  name: string
  role: string
  description: string
  profilePicture: GatsbyImageBasicProps
  socials: Array<SocialProps>
}

/*
 * Category
 */
export interface CategoryProps {
  id: string
  name: string
  pageInfo: PageInfoProps
}

/*
 * Post
 */

//
// PostObject is not exported, create new type picking what you need
//
interface PostObject {
  id: string
  metaFormatted: PublicationFormattedProps
  meta: PublicationProps
  title: string
  readingTime: string
  slug: string
  breadcrumb: Array<PostBreabcrumbItem>
  thumbnail: GatsbyImageBasicProps & { url?: string }
  categories: CategoryProps[]
  authors: Array<AuthorProps>
  seo: SEOProps
  content: StructuredText
  relatedLabel: string
  relatedPosts: PostPreviewWithCategoriesProps[]
}

export type PostProps = PostObject

export type PostPreviewProps = Pick<PostObject, 'id' | 'slug' | 'title' | 'readingTime' | 'thumbnail' | 'metaFormatted'>

export type PostPreviewWithCategoriesProps = Pick<
  PostObject,
  'id' | 'slug' | 'title' | 'readingTime' | 'thumbnail' | 'metaFormatted' | 'categories'
>

export type PostBreabcrumbItem = Pick<PostObject, 'id' | 'slug' | 'title'> & { shortTitle: string }

export type PostLinkProps = Pick<PostObject, 'id' | 'slug'> & { name: string }

export interface PostUtilsProps {
  aboutAuthors: string
  tableOfContentLabel: string
  shareLabel: string
  showPush: boolean
  headline: string
  button: ButtonProps[]
}

/*
 * Blog landing
 */
export interface BlogLandingProps {
  pageInfo: PageInfoProps
  meta: PublicationProps
  heroHeadline: string
  heroSubHeadline: string
  heroForm: HubspotFormProps | TypeformFormProps
  featuredPost: PostPreviewWithCategoriesProps
  allPostsLabel: string
  paginationPreviousLabel: string
  paginationNextLabel: string
}

/*
 * Block image
 */
export interface BlockImageProps extends Record {
  asset: GatsbyImageBasicProps & { url: string }
  canOpenInModal: boolean
}

/*
 * Block video
 */
export interface BlockVideoProps extends Record {
  video: {
    url: string
    provider: string
    providerUid: string
    thumbnailUrl: string
    width: number
    height: number
  }
}

/*
 * Block gated content
 */
export interface BlockGatedContentProps extends Record {
  asset: GatsbyImageBasicProps & { url: string }
  preHeadline: string
  headline: string
  subHeadline: string
  form: HubspotFormProps | TypeformFormProps
}

/*
 * Block Button
 */
export type BlockButtonProps = Record & ButtonProps

// TypeGuard
export function isImageBlock(block: Record): block is BlockImageProps {
  if (block?.__typename) {
    return (block as BlockImageProps).__typename === 'DatoCmsBlockImage'
  }
  return false
}

export function isVideoBlock(block: Record): block is BlockVideoProps {
  if (block?.__typename) {
    return (block as BlockVideoProps).__typename === 'DatoCmsBlockVideo'
  }
  return false
}

export function isGatedContentBlock(block: Record): block is BlockGatedContentProps {
  if (block?.__typename) {
    return (block as BlockGatedContentProps).__typename === 'DatoCmsBlockGatedContent'
  }
  return false
}

export function isButtonBlock(block: Record): block is BlockButtonProps {
  if (block?.__typename) {
    return (block as BlockButtonProps).__typename === 'DatoCmsButton'
  }
  return false
}
