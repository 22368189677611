import { PostProps } from '../types/blog'

export const getBreadcrumbSchema = (locale: string, post: PostProps) => {
  if (!post.breadcrumb || post.breadcrumb.length === 0) {
    return null
  }

  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      ...post.breadcrumb.map((breadcrumbItem, idx) => ({
        '@type': 'ListItem',
        position: idx + 1,
        name: breadcrumbItem.shortTitle ?? breadcrumbItem.title,
        item: `${process.env.SITE_URL}/${locale === 'en' ? '' : `${locale}/`}${breadcrumbItem.slug}`,
      })),
      {
        '@type': 'ListItem',
        position: post.breadcrumb.length + 1,
        name: post.title,
      },
    ],
  }
}
