import React from 'react'
// Types
import { PostProps } from '../../../types/blog'
// Contexts
import { useLocale } from '../../../hooks/useLocale'
// Components
import { Flex, Box, Heading, Image, Text } from '../../../atoms'
import { AuthorLight } from '../commons'
import { Link } from 'gatsby'

const PostHero: React.FC<PostProps> = ({ thumbnail, title, authors, readingTime, metaFormatted, breadcrumb }) => {
  const { localeSlug } = useLocale()
  return (
    <Box mb={[6, 7]}>
      <Box mb={[4, 5, 6]} borderRadius={'small'} overflow={'hidden'}>
        <Image alt={thumbnail.alt || title} image={thumbnail.gatsbyImageData} />
      </Box>

      {breadcrumb && breadcrumb.length > 0 && (
        <Box mb={4}>
          {breadcrumb.map((breadcrumbItem, idx) => (
            <>
              <Link key={breadcrumbItem.id} to={`/${localeSlug}${breadcrumbItem.slug}`}>
                <Text as={'span'} color={'darkBlue'} type={'small'} fontWeight={'ultraBold'}>
                  {breadcrumbItem.shortTitle ?? breadcrumbItem.title}
                </Text>
              </Link>
              {idx + 1 < breadcrumb.length && (
                <Box display={'inline'} mx={2}>
                  <Text as={'span'} type={'xsmall'} color={'grey20'}>
                    {'❯'}
                  </Text>
                </Box>
              )}
            </>
          ))}
        </Box>
      )}
      {/*<Box mb={4}>*/}
      {/*  {categories.map((c, i) => (*/}
      {/*    <Link key={c.id} to={`/${localeSlug}${c.pageInfo.slug}`}>*/}
      {/*      <Text as={'span'} color={'darkBlue'} type={'small'} fontWeight={'ultraBold'}>*/}
      {/*        {c.name}*/}
      {/*      </Text>*/}
      {/*      {i + 1 < categories.length && (*/}
      {/*        <Box display={'inline'} mx={1}>*/}
      {/*          <Text as={'span'} type={'small'}>*/}
      {/*            {'•'}*/}
      {/*          </Text>*/}
      {/*        </Box>*/}
      {/*      )}*/}
      {/*    </Link>*/}
      {/*  ))}*/}
      {/*</Box>*/}
      <Box>
        <Heading as={'h1'} type={'heading1'}>
          {title}
        </Heading>
      </Box>
      <Box mt={4}>
        <Text type={'small'} color={'grey50'}>
          {`${readingTime} • ${metaFormatted.firstPublishedAt || metaFormatted.createdAt}`}
        </Text>
      </Box>
      {authors.length > 0 && (
        <Flex mt={4} flexWrap={'wrap'}>
          {authors.map((author) => (
            <Box key={author.id} flex={['0 0 100%', '0 0 100%', '0 0 40%']} mr={6}>
              <AuthorLight
                profilePicture={author.profilePicture}
                name={author.name}
                role={author.role}
                pageInfo={author.pageInfo}
              />
            </Box>
          ))}
        </Flex>
      )}
    </Box>
  )
}

export default PostHero
